exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-pages-sektory-ogrodnictwo-tsx": () => import("./../../../src/pages/sektory/ogrodnictwo.tsx" /* webpackChunkName: "component---src-pages-sektory-ogrodnictwo-tsx" */),
  "component---src-pages-sektory-sektor-przemyslowy-tsx": () => import("./../../../src/pages/sektory/sektor-przemyslowy.tsx" /* webpackChunkName: "component---src-pages-sektory-sektor-przemyslowy-tsx" */),
  "component---src-pages-sektory-sektor-spozywczy-tsx": () => import("./../../../src/pages/sektory/sektor-spozywczy.tsx" /* webpackChunkName: "component---src-pages-sektory-sektor-spozywczy-tsx" */),
  "component---src-pages-sektory-warzywa-i-owoce-tsx": () => import("./../../../src/pages/sektory/warzywa-i-owoce.tsx" /* webpackChunkName: "component---src-pages-sektory-warzywa-i-owoce-tsx" */)
}

